@import url("https://fonts.googleapis.com/css?family=Montserrat");

*,
*:before,
*:after {
  box-sizing: border-box;
}

.pageBody{
    /*background-color: rgb(220,220,220);*/
    /*background: linear-gradient(180deg, #1e1e1e 10%,rgb(255,255,255) 100%);*/
    height:100%;
    overflow: auto;
    display:flex;
    justify-content: center;

    position: "absolute";
    top: 0;
    left: 0;
    width: "100%";
    height: "100%";
}

@keyframes hrColor{
    0%   {  border-color: #3c9cdb;
            background-color: #3c9cdb;
        }
    25%  {  border-color: #89dafa;
            background-color: #89dafa;
    }
    50%  {  border-color: #abc9a1;
            background-color: #abc9a1;
    }
    75% {   border-color: #4dc5ac;
            background-color: #4dc5ac;
    }
    100% {  border-color: #3c9cdb;
            background-color: #3c9cdb;
    }
  }

hr{

    border:solid;
    border-color:rgb(255, 255, 255);
    border-width:1px;
    margin: auto; 

    animation: hrColor 20s forwards;
    -moz-animation: hrColor 20s forwards;
    -webkit-animation: hrColor 20s forwards;
    -o-animation: hrColor 20s forwards;

    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;

}

.projectContainer{
    width:900px;
    display: flex;
    flex-wrap: wrap;
    /*background-color:rgb(133, 133, 133);*/
    background-color: rgba(245,245,245,0.8);
    padding:70px;
    margin:50px;
    
    border:0px solid;
    border-color: rgba(245,245,245,0.8);
    border-radius: 25px;

    filter: drop-shadow(0.0rem 0.0rem 0.8rem rgba(20, 20, 20,0.8));
    
}

.projectTextContainer{
    flex:50%;
    display:inline;
    padding-left:10%;
    font-family: monaco,Consolas,Lucida Console,monospace;
}



.projectTextContainer button{
    
    padding:10px;

    border-radius: 5px;

    /*background-color: rgb(135,135,135);*/
    background: linear-gradient(270deg, #d1d1d1 0%,#a8a8a8 100%);

    color:#1e1e1e;
}

.projectTextContainer h2, h4{
    color:#1e1e1e;
}

.projectTextContainer h2{
    font-size:35px;
}


.projectTextContainer h4{
    font-size:20px;
    font-weight: normal;
}

.projects{
    grid-area: part2;
    width:fit-content;
}

.verticalBreak{

    margin-top:50px;
    margin-bottom:50px;
    width:10px;
    height:100%;
    border-radius: 5px;
    margin-left: auto;
    background-color: rgb(135,135,135);

    color:green;

    grid-area: part1;
}

#tsparticles {
    position:fixed !important;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: -1;
}

.projectPageTitle{
    margin:75px 50px 70px 50px;
}

/*
@keyframes titleColor{
    0% { background-position: 0px 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0px 50%; }
  }*/

  
.projectPageTitle h4{
    font-size: 65px;
    font-family: monaco,Consolas,Lucida Console,monospace;
    width:fit-content;
    /*color: #1e1e1e;*/
    color: white;
    font-weight:bold;
    /*
    background: linear-gradient(-45deg, #3c9cdb, #89dafa,#abc9a1,#3c9cdb);
	background-size: 300%;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: titleColor 10s ease-in-out infinite;
	-moz-animation: titleColor 10s ease-in-out infinite;
	-webkit-animation: titleColor 10s ease-in-out infinite;*/
}
/*
.projectPageTitle hr{
  height: 2px;
  background: #c4e17f;
  background-image: linear-gradient(-45deg, #3c9cdb, #89dafa,#abc9a1,#3c9cdb);
  background-origin: content-box;
  background-size: 200%;
  animation-name: titleColor;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: steps(80);
  border:none;
}*/

/*
@keyframes enlargeImage{
    0%   {  width:240px;
        height:240px;
        }
    100% {  width:265px;
        height:265px;
    }
  }

  @keyframes shrinkImage{
    0%   {  width:265px;
            height:265px;
        }
    100% {  width:240px;
        height:240px;
    }
  }*/

.projectImage{
    width:240px;
    height:240px;
    transition:transform 250ms ease-in;
}

.projectImage:hover{
    transform: scale(1.05)
}




@media screen and (max-width: 600px){
    .projectContainer{
        justify-content: center;
        padding:5vw;
        width:80vw;
        margin:5vw;
        
    }

    .projectTextContainer{
        padding:0px;
    }
}
@media screen and (max-width: 1000px)and (min-width: 600px){

    
    .projectContainer{
        justify-content: center;
        width:70vw;
        
    }

    .projectTextContainer{
        flex:100%;
        padding-left:0%;
        justify-content: center;
        padding:0px;
    }

    .projectImageContainer{
        flex:100%;
        display: flex;
        justify-content: center;
    }
    .projectImageContainer img{
    }

}

@media screen and (max-width: 768px){

    .projectPageTitle{
        margin:75px 10vw 70px 10vw;
    }

    .projectPageTitle h4{
        font-size: 45px;
    }

    .projectTextContainer h2{
        font-size:25px;
    }

}