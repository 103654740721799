@import url("https://fonts.googleapis.com/css?family=Montserrat");

/*
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
*/


@import "~react-responsive-carousel/lib/styles/carousel.min.css"


*,
*:before,
*:after {
    box-sizing: border-box;
}


.projectBody {
    background-color: rgba(255, 255, 255, 0.7);
    height: fit-content;
    min-height: calc(60vh - 65px - 65px);

    border-radius: 100px;
    padding: 100px;

    margin: 0vh 10vw 10vh 10vw;


    /*filter: drop-shadow(-0.3rem 0.3rem 0.5rem rgb(179, 179, 179));*/

    font-family: monaco, Consolas, Lucida Console, monospace;

    filter: drop-shadow(-0.3rem 0.3rem 0.5rem rgb(179, 179, 179));
}

.projectBody img {}

.projectBody h2 {
    font-weight: normal;
    font-size: 20px;
    padding-left: 15px;

}

.projectBody h4 {
    font-size: 30px;
    padding-bottom: 10px;
}

.projectTitle {
    margin: 70px 50px 120px 50px;
}

.projectTitle h4 {
    font-size: 65px;
    font-family: monaco, Consolas, Lucida Console, monospace;
    width: fit-content;
    /*color: #1e1e1e;*/
    color: white;
}

@media screen and (max-width: 768px) {

    .projectTitle h4 {
        font-size: 45px;
    }

    .projectTitle {
        margin: 75px 10vw 70px 10vw;
    }

    .projectBody {
        border-radius: 7vw;
        padding: 10vw;
        margin: 3vw;
    }

    .projectBody h2 {
        font-size: 2vw;
        padding-left: 15px;

    }

    .projectBody h4 {
        font-size: 3vw;
        padding-bottom: 10px;
    }


}


.container {
    padding: 40px;
    background: #419be0;
}

.slick-slide img {}

.projectImages {

    max-width: 300px;

}

.projectBreaker {
    flex: 5%;
    max-width: 100px;
}

.projectDescription {
    flex: 20%;
}

.projectBody {}

.hrIfSmallScreen {
    display: none;
}


@media screen and (max-width: 1200px) {

    .projectBody {}

    .projectImages {
        margin-left: calc(50% - 150px);
        flex: 100%;
    }

    .projectBreaker {
        display: none;
    }

    .projectDescription {
        flex: 100%;
    }

    .hrIfSmallScreen {
        display: block;
    }

}





.verticalBreak {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 2px;
    height: 100%;
    border-radius: 1px;
    background-color: rgb(135, 135, 135);
    margin-right: 49%;

    animation: lineColor 20s forwards;
    -moz-animation: lineColor 20s forwards;
    -webkit-animation: lineColor 20s forwards;
    -o-animation: lineColor 20s forwards;

    animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
}


@keyframes lineColor {
    0% {
        border-color: #3c9cdb;
        background-color: #3c9cdb;
    }

    25% {
        border-color: #89dafa;
        background-color: #89dafa;
    }

    50% {
        border-color: #abc9a1;
        background-color: #abc9a1;
    }

    75% {
        border-color: #4dc5ac;
        background-color: #4dc5ac;
    }

    100% {
        border-color: #3c9cdb;
        background-color: #3c9cdb;
    }
}


.projectImageContainer {
    opacity: 1.0;
}


.projectImage {
    border-radius: 10px;
    max-width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
}