@import url("https://fonts.googleapis.com/css?family=Montserrat");

*,
*:before,
*:after {
  box-sizing: border-box;
}


.AccoladesBody{
    background-color: white;
    height:fit-content;
    min-height:calc(60vh - 65px);

    border-radius: 100px;
    padding:100px;

    margin:0vh 10vw 10vh 10vw;
    

    /*filter: drop-shadow(-0.3rem 0.3rem 0.5rem rgb(179, 179, 179));*/

    font-family: monaco,Consolas,Lucida Console,monospace;

    filter: drop-shadow(-0.3rem 0.3rem 0.5rem rgb(179, 179, 179));
    opacity:0.9;
}

.AccoladesBody h2{
    font-weight:normal;
    font-size:20px;
    padding-left: 15px;
    
}

.AccoladesBody h4{
    font-size:30px;
    padding-bottom: 10px;
}

.accoladesTitle{
    margin:70px 50px 120px 50px;
}

/*
@keyframes titleColor{
    0% { background-position: 0px 50%; }
	50% { background-position: 100% 50%; }
	100% { background-position: 0px 50%; }
  }*/

  
.accoladesTitle h4{
    font-size: 65px;
    font-family: monaco,Consolas,Lucida Console,monospace;
    width:fit-content;
    /*color: #1e1e1e;*/
    color: white;
    /*
    background: linear-gradient(-45deg, #3c9cdb, #89dafa,#abc9a1,#3c9cdb);
	background-size: 300%;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: titleColor 10s ease-in-out infinite;
	-moz-animation: titleColor 10s ease-in-out infinite;
	-webkit-animation: titleColor 10s ease-in-out infinite;*/
}

@media screen and (max-width: 768px){

    .AccoladesBody{
        border-radius: 10vw;
        padding:7vw;
    }

    .AccoladesBody h2{
        font-size:2vw;
        padding-left: 15px;
        
    }
    
    .AccoladesBody h4{
        font-size:3vw;
        padding-bottom: 10px;
    }

    .accoladesTitle h4{
        font-size: 45px;
    }

    .accoladesTitle{
        margin:75px 10vw 70px 10vw;
    }
    
}