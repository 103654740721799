@import url("https://fonts.googleapis.com/css?family=Montserrat");

*,
*:before,
*:after {
  box-sizing: border-box;
}

*{
    overflow: none;
    
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
/* 
.FooterContainer{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
} */

.footerTop{
    display:flex;
    justify-content: space-evenly;
    padding-top:25px;
    padding-left:30vw;
    padding-right:30vw;

    background-color: rgb(57,57,57);
    height:100px;
}

@media screen and (max-width: 900px){
    .footerTop{
        padding-left:17vw;
        padding-right:17vw;
    }
    

    
}

.footerBottom{
    background-color: rgb(30,30,30);
    height:30px;
    padding-top:5px;
}

.footerTop h4{
    font-family: monaco,Consolas,Lucida Console,monospace;
    color:rgb(220,220,220);
    
}

.footerBottom h4{
    font-family: monaco,Consolas,Lucida Console,monospace;
    margin-left: 15px;
    color:rgb(220,220,220);
}

.contactButton{
    color:white;
}


.contactButton {
    color: white;
    transition: color 250ms ease-out;
}
  
.contactButton:hover {
    color: gray;
}

@media screen and (min-width: 768px){
    .smallWidthName{
        display:none;
    }
    .dropDownButtonContainer{
        display:none;
    }

    
}

