@import url("https://fonts.googleapis.com/css?family=Montserrat");

*,
*:before,
*:after {
  box-sizing: border-box;
}

*{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

.profilePicture{
    width:40px;
}

.profilePictureContainer{
    display:grid;
    grid-template-areas:'profile-pic full-name';
    align-items: center;
    
    width:fit-content;

    margin:15px;
}

.smallNav{
    font-family: monaco,Consolas,Lucida Console,monospace;
}


.Navbar{
    
    
    font-family: monaco,Consolas,Lucida Console,monospace;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    
    overflow:hidden;
    background-color:#1e1e1e;
}

.nameBar div{
    display:inline;
    font-family: monaco,Consolas,Lucida Console,monospace;
}

.NavbarContainer{
    z-index: 1;
    position:fixed;
    top:0px;
    width:100%;
}

.nameBar{
    width: fit-content;
    margin-right:15px;

    color:white;
    font-size: 15px;

    display: flex;
    align-items: center;
}

.smallWidthName div{
    display:inline;
}

.smallWidthName{
    font-family: monaco,Consolas,Lucida Console,monospace;
    display: flex;
    align-items: center;
}

.dropDownButtonContainer{
    display: flex;
    margin:15px;
}

.dropDownButton{
    width: 35px;
    color:white; 
    transition: color 250ms ease-in;   
}

.dropDownButton:hover{
    color:grey;    
}


@media screen and (max-width: 767px){
    .nameBar{
        display:none;
        
    }
    .Navbar{
        justify-content: center;
        justify-content: space-between;
        
    }
}

@media screen and (min-width: 768px){
    .smallWidthName{
        display:none;
    }
    .dropDownButtonContainer{
        display:none;
    }

    .smallNav{
        display:none;
    }
}


.Navbar{
    height:65px;
}

.dropDown {
    position:relative;
    background-color: #1e1e1e;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    animation: fadeDown 250ms ease-out forwards;
  }

  .dropDown div{
    font-size: 15px;
    margin:10px;
    font-family: monaco,Consolas,Lucida Console,monospace;
    animation: fadeIn 250ms ease-out forwards;
  }
  
  @keyframes fadeDown {
    0% {
        height:0px;
    }
    100% {
        height:95px;
    }
  }

  @keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
  }
