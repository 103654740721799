@import url("https://fonts.googleapis.com/css?family=Montserrat");


.AboutMeContainer{
    margin:0;
    font-size: 75px;
    font-family: monaco,Consolas,Lucida Console,monospace;
    color: #1e1e1e;
}

.AboutMeContainer h2{

    font-size: 35px;
    
}


.AboutMeTopSection{
    
    height:calc(100vh - 65px);
    min-height: 600px;

    -ms-transform: translateY(25vh);
    transform: translateY(calc(50vh - 65px - min(70vw, 70vh)/2));

    
}

.AboutMeBottomSection{
    background-color: rgb(240,240,240);
    height:fit-content;
    width: 100%;
    display: inline-block;
    padding:120px 70px 120px 70px;
}


.AboutMeBottomSection h2{

    font-size: 25px;

    font-weight: normal;
    
}

.centerProfileImage{
    width:300px;
    margin-top:0px;
}

@media screen and (max-width: 768px){
    .AboutMeContainer{
        margin:0;
        font-size: 33px;
        font-family: monaco,Consolas,Lucida Console,monospace;
        color: #1e1e1e;
    }

    .AboutMeContainer h2{

        font-size: 16px;
        
    }

    .AboutMeBottomSection h2{

        font-size: 16px;
        
    }

    .AboutMeBottomSection h4{

        font-size: 16px;
        
    }

    .AboutMeBottomSection{
        background-color: rgb(240,240,240);
        height:fit-content;
        width: 100%;
        display: inline-block;
        padding:120px 10vw 120px 10vw;
        
    }

    .centerProfileImage{
        width:200px;
        margin-top:0px;
    }
    
}

@media screen and (max-width: 1500px) and (min-width: 768px){
    .AboutMeContainer{
        margin:0;
        font-size: 60px;
        font-family: monaco,Consolas,Lucida Console,monospace;
        color: #1e1e1e;
    }

    .AboutMeContainer h2{

        font-size: 28px;
        
    }

    .AboutMeBottomSection h2{

        font-size: 25px;
        
    }

    .AboutMeBottomSection h4{

        font-size: 25px;
        
    }
    .centerProfileImage{
        width:250px;
        margin-top:0px;
    }
    
}

.dynamicFaceContainer{
    /*width:300px;
    height:300px;*/

    width:min(60vw, 60vh);
    height:min(60vw, 60vh);
    
    /*border-style: solid;
    border-width: 10px;*/
}